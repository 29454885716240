import React from "react";
import Helmet from "react-helmet";
import {graphql} from "gatsby";
import Layout from "../components/layout";

export default function Template({data}) {
  const post = data.markdownRemark;
  return (
      <Layout className="blog-post-container">
        <Helmet title={post.frontmatter.title}/>
        <div className="blog-post">
          <h1>{post.frontmatter.title}</h1>
          <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{__html: post.html}}
          />
        </div>
      </Layout>
  );
}

export let pageQuery = graphql`
  query BlogPost($id: String!) {
    markdownRemark(id: { eq: $id }) {
      timeToRead
      html
      excerpt(pruneLength: 400)
      frontmatter {
        title
        date
      }
    }
  }
`